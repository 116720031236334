import { StyleSheet, css } from "aphrodite";

import { COLORS } from "~/config/themes";

const Button = (props) => {
  let { buttonStyles, icon, iconStyles, disabled } = props;
  return (
    <button
      className={css(
        styles.button,
        styles.newButton,
        props.className,
        buttonStyles && buttonStyles
      )}
      onClick={props.onClick}
      disabled={disabled}
    >
      <div className={css(styles.icon, iconStyles && iconStyles)}>
        {icon ? icon : null}
      </div>
      {props.text}
    </button>
  );
};

const styles = StyleSheet.create({
  newButton: {
    background: "rgb(234, 88, 12)",
    width: "100%",
    paddingTop: ".75rem",
    paddingBottom: ".75rem",
    lineHeight: "1.5rem",
    fontWeight: 600,
    borderRadius: ".375rem",
    fontFamily: "Inter",
    fontSize: 16,
    height: "unset",

    "@media only screen and (max-width: 767px)": {
      fontSize: 16,
      padding: 10,
    },
    ":disabled": {
      cursor: "not-allowed",
      backgroundColor: "rgba(210, 212, 216, 1)",
    },
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: 16,
    transform: "translateY(-50%)",
  },
  button: {
    width: 165,
    height: 60,
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    color: "#fff",
    outline: "none",
    borderRadius: 2,
    cursor: "pointer",
    position: "relative",

    background: COLORS.BLUE(),

    ":hover": {
      opacity: 0.7,
    },

    "@media only screen and (max-width: 767px)": {
      ":hover": {
        opacity: 1,
      },
    },
  },
});

export default Button;
